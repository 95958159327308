// system imports
import * as sservice from './_standard-service'
 
const SERVICE_PATH = 'phrases';
  
const get = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/${params.language}/`
    }

    return sservice.get(sparams, params, dispatch)
}

const getBooks = async (params, dispatch) => {
    const sparams = {
        path: `${SERVICE_PATH}/${params.language}/books`
    }
    return sservice.get(sparams, params, dispatch)
}

const getGlossary = async (params, dispatch) => {
    const sparams = {
        path: `${SERVICE_PATH}/${params.language}/glossary`
    }
    return sservice.get(sparams, params, dispatch)
}

export { get, getBooks, getGlossary }