import React from 'react';
import { Spinner, Button } from 'react-bootstrap';
import './landing-main.css'
import screenshot001 from './images/Portrait.001.png';
import AppleStoreBadgeES from './images/App_Store_Badge_ES.svg';
import AppleStoreBadgeUS from './images/App_Store_Badge_US.svg';
import GooglePlayBadgeES from './images/GetItOnGooglePlay_Spanish.png';
import GooglePlayBadgeUS from './images/GetItOnGooglePlay_English.png';

const MainSection = ({ t, i18n, loadingPhrase, phrase, onSignInClick }) => {
  return (
    <div className="main-section">
      
      <div className="main-content">
          <div className="title-group">
              <h2 className="main-title">{t('mainTitle')}</h2>
                <div className="store-buttons-container">
                    {i18n.language === 'es' ? (
                    <>
                        <a href="https://apps.apple.com/co/app/fgo360/id6737204398" target="_blank" rel="noopener noreferrer">
                        <img 
                            src={AppleStoreBadgeES} 
                            alt="Descargar en App Store" 
                            className='apple-store-badge'
                        />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.fgo360.app" target="_blank" rel="noopener noreferrer">
                        <img 
                            src={GooglePlayBadgeES}
                            alt="Disponible en Google Play" 
                            className='google-play-badge'
                        />
                        </a>
                    </>
                    ) : (
                    <>
                        <a href="https://apps.apple.com/us/app/fgo360/id6737204398" target="_blank" rel="noopener noreferrer">
                        <img 
                            src={AppleStoreBadgeUS} 
                            alt="Download on App Store" 
                            className='apple-store-badge'
                        />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.fgo360.app" target="_blank" rel="noopener noreferrer">
                        <img 
                            src={GooglePlayBadgeUS}
                            alt="Get it on Google Play" 
                            className='google-play-badge'
                        />
                        </a>
                    </>
                    )}
                </div>
          </div>
      </div>

      {/* Contenido Secundario */}
      <div className="secondary-content">
          <div className="content-wrapper">
              <h5 className="subtitle">{t('subTitle')}</h5>
              
          </div>

          <div className="image-container">
              <img 
                  src={screenshot001} 
                  alt="FGo360 App" 
                  className="main-image"
              />
          </div>
      </div>

      
    </div>
  );
};

export default MainSection;