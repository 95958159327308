import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import { idProviderGoogle } from '../../utilities/services/user-service'

const AuthGoogleComponent = ({locale}) => {
 
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const handleSuccess = async (credentialResponse) => {
    try {
      setIsLoading(true);
      const decodedUser = jwtDecode(credentialResponse.credential);
      const response = await idProviderGoogle(decodedUser, dispatch);
      
      if (response.success) {
        navigate('/');
      }
    } catch (error) {
      console.error(t('idprovider.google.error'), error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleError = () => {
    console.error(t('idprovider.google.error'));
    setIsLoading(false);
  };
 

  if (isLoading) {
    return (
        <div className="d-flex align-items-center justify-content-center p-2 rounded border" 
            style={{
            height: '40px',
            backgroundColor: '#fff',
            boxShadow: '0 1px 2px 0 rgba(0,0,0,.1)',
            border: '1px solid #dadce0'
            }}>
        <div className="spinner-border spinner-border-sm text-primary me-2" 
            style={{ 
                width: '18px', 
                height: '18px',
                borderWidth: '2px',
                color: '#1a73e8'
            }} 
            role="status">
        <span className="visually-hidden">{t('idprovider.loading')}</span>
        </div>
        <span style={{ 
        color: 'rgba(0,0,0,0.54)', 
        fontSize: '14px',
        fontFamily: 'Roboto, arial, sans-serif',
        fontWeight: '500'
        }}>
        {t('idprovider.google.authenticating')}
        </span>
    </div>
    );
  }
  
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <GoogleLogin
            onSuccess={handleSuccess}
            onError={handleError}
            useOneTap={false}
            locale={locale}
            text="signin_with"
            shape="rectangular"
            prompt="select_account"
        />
    </GoogleOAuthProvider>
  );
};

export default AuthGoogleComponent;