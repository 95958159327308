import React, { Fragment } from "react";
  
import Icon from '@mdi/react';
import Tooltip from '@mui/material/Tooltip';
import {
     
    mdiFileHidden,
    mdiFileDocumentOutline,
    mdiCogOutline,
    mdiFileTreeOutline,
    mdiFamilyTree,
    mdiAccountCogOutline,
    mdiTextBoxMultipleOutline,
    mdiFileOutline,
    mdiContentCopy,
    mdiCartOutline,
    mdiViewDashboard,
    mdiTextBoxOutline,
    mdiChartBar,
    mdiFileMoveOutline,
    mdiStorefrontOutline,
    mdiDomain,
    mdiAccount,
    mdiTools,
    mdiEmailPlusOutline,
    mdiMessageFastOutline,
    mdiEmailMinusOutline,
    mdiEmailArrowRightOutline,
    mdiEmailArrowLeftOutline,
    mdiEmailFastOutline,
    mdiBasketPlusOutline, 
    mdiDatabaseImportOutline, 
    mdiShoppingOutline, 
    mdiCashPlus, 
    mdiDatabasePlusOutline, 
    mdiCash, 
    mdiAccountCash, 
    mdiCashMinus, 
    mdiDatabaseOutline, 
    mdiCashFast, 
    mdiCashRefund, 
    mdiDatabaseMinusOutline, 
    mdiBankOutline, 
    mdiCreditCardSettingsOutline,
    mdiClipboardTextOutline,
    mdiEmailSyncOutline,
    mdiFaceManProfile,
    mdiFaceAgent,
    mdiHelp,
    mdiAccountCashOutline,
    mdiInvoiceTextFastOutline,
    mdiWalletBifoldOutline
} from '@mdi/js';
  
import configs from '../../utilities/configuration-container'
import './icon.css'
 
export const typePath = (obj) => {
 
    switch (obj) {

        case configs.assetEmail.object.name:
        case configs.liabilityPaymentEmail.object.name:
        case configs.expenseEmail.object.name:
        case configs.incomeEmail.object.name:
        case configs.transactionEmail.object.name:
        case configs.transactionSMS.object.name:
        case configs.userEmail.object.name:
            return { tcolor: '#707070', bcolor: '#C2C2C2', type: 'automaticemail' }

        case configs.expense.object.name:
        case configs.expenseTemplate.object.name:
            return { tcolor: '#CC6666', bcolor: '#E53935', type: 'transaction'};
        case configs.accountExpense.object.name:
            return { tcolor: '#CC6666', bcolor: '#C62828', type: 'transaction'};

        case configs.income.object.name:
        case configs.incomeTemplate.object.name:
            return { tcolor: '#66CC66', bcolor: '#43A047', type: 'transaction'};
        case configs.accountIncome.object.name:
            return { tcolor: '#66CC66', bcolor: '#388E3C', type: 'transaction'};

        case configs.asset.object.name:
        case configs.assetTemplate.object.name:
        case configs.accountAsset.object.name:
            return { tcolor: '#607D8B', bcolor: '#1E88E5', type: 'transaction'};

        case configs.liability.object.name:
        case configs.accountLiability.object.name:
            return { tcolor: '#607D8B', bcolor: '#D32F2F', type: 'transaction'};

        case configs.liabilityPayment.object.name:
        case configs.liabilityPaymentTemplate.object.name:
            return { tcolor: '#1976D2', bcolor: '#66BB6A', type: 'transaction'};

        case configs.dashboard.object.name:
        case configs.report.object.name:
        case configs.chart.object.name:
        case configs.dashboardStandard.object.name:
        case configs.reportStandard.object.name:
        case configs.chartStandard.object.name:
        case configs.reportType.object.name:
            return { tcolor: '#0077be', bcolor: '#0277BD', type: 'data'};

        case configs.vendor.object.name:
            return { tcolor: '#4F9999', bcolor: '#26A69A', type: 'thirdparty'};
        case configs.buyer.object.name:
            return { tcolor: '#4F9999', bcolor: '#00897B', type: 'thirdparty'};
        case configs.expenseBudget.object.name:
            return { tcolor: '#4F9999', bcolor: '#26A69A', type: 'thirdparty'};
            

        case configs.paymentMethodBank.object.name:
        case configs.paymentMethodCash.object.name:
        case configs.paymentMethodCard.object.name:
        case configs.paymentMethodWallet.object.name:
            return { tcolor: '#4EAAE5', bcolor: '#039BE5', type: 'paymentmethod'};

        case configs.ticket.object.name:
            return { tcolor: '#333333', bcolor: '#333333', type: 'support'};

        case configs.profile.object.name:
            
            return { tcolor: '#333333', bcolor: '#333333', type: 'support'};

        default:
            return { tcolor: '#333333', bcolor: '#333333', type: 'unknown'};
    }
};
 
const iconPath = (obj) => {
 
    switch (obj) {
        case configs.assetEmail.object.name:
            return { primary: mdiEmailPlusOutline }; // example for adding a secondary icon
       
            
        case configs.liabilityPaymentEmail.object.name:
            return { primary: mdiEmailMinusOutline };
        case configs.expenseEmail.object.name:
            return { primary: mdiEmailArrowRightOutline };
        case configs.incomeEmail.object.name:
            return { primary: mdiEmailArrowLeftOutline };
        case configs.transactionEmail.object.name:
            return { primary: mdiEmailFastOutline };
        case configs.transactionSMS.object.name:
                return { primary: mdiMessageFastOutline }; // example for adding a secondary icon
        case configs.userEmail.object.name:
            return { primary: mdiEmailSyncOutline };

        case configs.expense.object.name:
            return { primary: mdiFileMoveOutline, secondary: mdiShoppingOutline };
        case configs.expenseTemplate.object.name:
            return { primary: mdiFileOutline, secondary: mdiShoppingOutline };
        case configs.accountExpense.object.name:
            return { primary: mdiFileTreeOutline, secondary: mdiShoppingOutline };

        case configs.income.object.name:
            return { primary: mdiFileMoveOutline, secondary: mdiCashPlus };
        case configs.incomeTemplate.object.name:
            return { primary: mdiFileOutline, secondary: mdiCashPlus };
        case configs.accountIncome.object.name:
            return { primary: mdiFileTreeOutline, secondary: mdiCashPlus };

        case configs.asset.object.name:
            return { primary: mdiFileMoveOutline, secondary: mdiAccountCashOutline };
        case configs.accountAsset.object.name:
            return { primary: mdiFileTreeOutline, secondary: mdiAccountCashOutline };
        case configs.assetTemplate.object.name:
            return { primary: mdiFileOutline, secondary: mdiAccountCashOutline };

        case configs.liability.object.name:
            return { primary: mdiFileMoveOutline, secondary: mdiInvoiceTextFastOutline };
        case configs.accountLiability.object.name:
            return { primary: mdiFileTreeOutline, secondary: mdiInvoiceTextFastOutline };

        case configs.liabilityPayment.object.name:
            return { primary: mdiFileMoveOutline, secondary: mdiCashFast };
        case configs.liabilityPaymentTemplate.object.name:
            return { primary: mdiFileHidden, secondary: mdiCashFast };

        case configs.dashboard.object.name:
            return { primary: mdiViewDashboard };

        case configs.report.object.name:
            return { primary: mdiTextBoxOutline };

        case configs.chart.object.name:
            return { primary: mdiChartBar };

        case configs.dashboardStandard.object.name:
            return { primary: mdiViewDashboard };

        case configs.reportStandard.object.name:
            return { primary: mdiTextBoxOutline };

        case configs.chartStandard.object.name:
            return { primary: mdiChartBar };

        case configs.reportType.object.name:
            return { primary: mdiTextBoxMultipleOutline };

       

        case configs.vendor.object.name:
            return { primary: mdiStorefrontOutline };

        case configs.buyer.object.name:
            return { primary: mdiDomain };

        case configs.expenseBudget.object.name:
            return { primary: mdiClipboardTextOutline };

        case configs.profile.object.name:
            return { primary: mdiAccountCogOutline };

        case configs.paymentMethodBank.object.name:
            return { primary: mdiBankOutline };
        case configs.paymentMethodCash.object.name:
            return { primary: mdiCash };
        case configs.paymentMethodCard.object.name:
            return { primary: mdiCreditCardSettingsOutline };
        case configs.paymentMethodWallet.object.name:
            return { primary: mdiWalletBifoldOutline };

        case configs.ticket.object.name:
            return { primary: mdiFaceAgent };

        case 'tools':
            return { primary: mdiCogOutline };

        case 'help':
            return { primary: mdiHelp };

        default:
            return { primary: null, secondary: null };
    }
};

const MaterialIcon = ({obj, tooltip, isheader, placement}) => {
      
    const typeInfo = typePath(obj)
    const iconInfo = iconPath(obj)
 
    return (
        <span className={`material-icon material-icon-${obj} material-icon-type-${typeInfo.type}` } style={{borderColor: typeInfo.bcolor}}>
            <Tooltip title={tooltip} placement={placement? placement: 'right'}>
                <div  className="material-icon-holder" >
                    <div className="material-icon-container" >
                        <Icon path={iconInfo.primary} className="icon-option-primary" style={{color: typeInfo.bcolor}}/>
                    </div>
                </div>
            </Tooltip>
        </span>
    );
    
}
  
export default MaterialIcon