import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './landing-glossary.css';
import { useDispatch } from 'react-redux';
import { getGlossary } from '../../utilities/services/phrase-service';
import { useTranslation } from 'react-i18next';

const LandingGlossary = () => {
    const [terms, setTerms] = useState([]);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const fetchTerms = async () => {
            const response = await getGlossary({ language: i18n.language }, dispatch);
            if (response.success) {
                setTerms(response.data);
            }
        };
        fetchTerms();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true
    };

    return (
        <div className="lg-glossary-container">
            <h2 className="section-title">{t('landing-glossary.title')}</h2>
            <div className="lg-glossary-slider">
                <Slider {...settings}>
                    {terms.map((item, index) => (
                        <div className="lg-term-card" key={index}>
                            <h3 className="lg-term-title">{item.term}</h3>
                            <div className="lg-term-content">
                                <div className="lg-term-section">
                                    <h4>{t('landing-glossary.definition')}:</h4>
                                    <p>{item.description}</p>
                                </div>
                                <div className="lg-term-section">
                                    <h4>{t('landing-glossary.usageExample')}:</h4>
                                    <p>{item.usage}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default LandingGlossary; 