import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './landing-features.css';

import screenshotBank from './images/screenshot-bank.png';
import screenshotCategories from './images/screenshot-categories.png';
import screenshotAi from './images/screenshot-ai.png';
import screenshotReports from './images/screenshot-reports.png';

// SVG Icons components
const BankTransactionIcon = () => (
    <svg className="feature-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
        <path d="M4 10V4a2 2 0 0 1 2-2h12a2 2 0 1 1 2 2v6M4 10h16M4 10l2 10h12l2-10M12 14v-2M8 14v-2M16 14v-2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 7h.01M15 7h.01M9 7h.01" strokeLinecap="round"/>
    </svg>
);

const CategorizationIcon = () => (
    <svg className="feature-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
        <path d="M4 4h6v6H4zM14 4h6v6h-6zM4 14h6v6H4zM14 14h6v6h-6z" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7 7L7 7M17 7L17 7M7 17L7 17M17 17L17 17" strokeLinecap="round"/>
    </svg>
);

const AIAssistantIcon = () => (
    <svg className="feature-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
        <path d="M12 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"/>
        <path d="M15 7H9a2 2 0 00-2 2v10a2 2 0 002 2h6a2 2 0 002-2V9a2 2 0 00-2-2z"/>
        <path d="M12 8v8M8 12h8" strokeLinecap="round"/>
        <path d="M9 16c.9.6 1.9 1 3 1s2.1-.4 3-1"/>
    </svg>
);

const ReportsIcon = () => (
    <svg className="feature-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
        <path d="M4 4v16c0 1.1.9 2 2 2h12a2 2 0 002-2V8l-6-6H6a2 2 0 00-2 2z" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14 2v6h6M8 13h8M8 17h8M8 9h2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const featureIcons = {
    'bank': BankTransactionIcon,
    'categories': CategorizationIcon,
    'ai': AIAssistantIcon,
    'reports': ReportsIcon
};

function LandingFeatures({ t }) {
    const features = [
        { id: 'bank', title: 'landing-page.feature1', desc: 'landing-page.feature1Description', screenshot: screenshotBank },
        { id: 'categories', title: 'landing-page.feature2', desc: 'landing-page.feature2Description', screenshot: screenshotCategories },
        { id: 'ai', title: 'landing-page.feature3', desc: 'landing-page.feature3Description', screenshot: screenshotAi },
        { id: 'reports', title: 'landing-page.feature4', desc: 'landing-page.feature4Description', screenshot: screenshotReports }
    ];

    return (
        <div className="features-container">
            <div className="text-center mb-5">
                <h2 className="section-title">{t('landing-page.features')}</h2>
            </div>
            {features.map((feature, index) => {
                const IconComponent = featureIcons[feature.id];
                const isImageLeft = index % 2 === 1;
                return (
                    <div key={feature.id} className="feature-section">
                        <Row className={`feature-row align-items-center ${isImageLeft ? 'flex-row-reverse' : ''}`}>
                            <Col xs={12} md={6} className="feature-content-container">
                                <IconComponent />
                                <h4 className="feature-title">{t(feature.title)}</h4>
                                <p className="feature-description">{t(feature.desc)}</p>
                            </Col>
                            <Col xs={12} md={6} className="feature-screenshot-container">
                                <img 
                                    src={feature.screenshot} 
                                    alt={t(`${feature.title} screenshot`)} 
                                    className="feature-screenshot" 
                                />
                            </Col>
                        </Row>
                        <div className="decorative-divider">
                            <div className="divider-line"></div>
                            <div className="divider-line"></div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default LandingFeatures;
