// system imports
import * as gservice from './_generic-service'
import * as sservice from './_standard-service'

import * as actions from '../redux/actions/payment-method-slice';
 
const SERVICE_PATH = 'payment_methods';
 
const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})
  
export const { getAll, get, add, update, remove, clone } = fns

const getAllByType = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/list/${params.payload.type}${(params.payload.text?'?t=' + params.payload.text: '')}`,
        action: actions.getAllByType,
        actionResponse: actions.getAllByTypeResponse,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}

const getTargetIncome = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/target/income`,
        action: actions.getParent,
        actionResponse: actions.getParentResponse,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}

const getParentByType = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/parent/${params.payload.type}`,
        action: actions.getParent,
        actionResponse: actions.getParentResponse,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}


export { getAllByType, getParentByType, getTargetIncome }