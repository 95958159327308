import React, { useState, useEffect } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import * as uservice from '../../utilities/services/user-service';
import * as uactions from '../../utilities/redux/actions/user-slice';
import AuthGoogleComponent from '../idproviders/google';
import './landing-signin.css';

const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};

function LandingSignin({ t, i18n, handleBackToHome }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const error = useSelector((state) => state.user.error);

    // Login states
    const [email, setEmail] = useState('');
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [userData, setUserData] = useState(null);
    const [token, setToken] = useState();

    // Verification states
    const [isVerification, setIsVerification] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationError, setVerificationError] = useState('');
    const [time, setTime] = useState(180);
    const [tooManyAttempts, setTooManyAttempts] = useState(false);

    useEffect(() => {
        dispatch(uactions.error());
    }, [dispatch]);

    useEffect(() => {
        if (isVerification) {
            const intervalId = setInterval(() => {
                setTime((currentTime) => {
                    if (currentTime <= 0) {
                        clearInterval(intervalId);
                        handleCancel();
                        return 0;
                    }
                    return currentTime - 1;
                });
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [isVerification]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const handleEmailSignIn = async (e) => {
        e.preventDefault();
        dispatch(uactions.error());
        if (!validateEmail(email)) {
            setEmailInvalid(true);
            return;
        }

        const currentUserData = {
            rememberMe: rememberMe,
            time_zone: new Date().getTimezoneOffset(),
            time_zone_text: DateTime.local().zoneName,
            initial_language_text: i18n.language
        };

        setUserData(currentUserData);

        const response = await uservice.login(
            {
                email: email,
                userData: currentUserData
            },
            dispatch
        );

        if (response.success) {
            setToken(response.data.token);
            setIsVerification(true);
            setTime(180);
            setTooManyAttempts(false);
        }
    };

    const handleVerification = async () => {
        try {
            if (!verificationCode.trim()) {
                setVerificationError(t('verificationPage.emptyCode') || 'Please enter a verification code');
                return;
            }
    
            const response = await uservice.activate(
                {
                    email,
                    token,
                    code: verificationCode,
                    userData: userData,
                },
                dispatch
            );
    
            if (!response) {
                setVerificationError(
                    t('landing-signin.verification.server_error') || 
                    'Server error occurred. Please try again.'
                );
                return;
            }
    
            if (response.success) {
                // Navegar a otra vista, por ejemplo, el dashboard principal
                navigate('/');
            } else {
                
                switch (response.message) {
                    case 'AUTHENTICATION_INVALID_TOKEN':
                        setVerificationError(
                            response.message.message || 
                            t('landing-signin.verification.invalid_token') || 
                            'El token de activación no es válido o ha expirado'
                        );
                        break;
                    case 'MAX_ATTEMPTS_EXCEEDED':
                        setTooManyAttempts(true);
                        setVerificationError(
                            t('landing-signin.verification.too_many_attempts') || 
                            'You have reached the maximum number of verification attempts'
                        );
                        break;
                    default:
                        setVerificationError(
                            response.message.message || 
                            t('landing-signin.verification.invalid_code') || 
                            'Invalid verification code'
                        );
                }
            }
        } catch (error) {
            console.error('Verification error:', error);
            setVerificationError(
                t('landing-signin.verification.general_error') || 
                'An error occurred during verification. Please try again later.'
            );
        }
    };

    const handleCancel = () => {
        setIsVerification(false);
        setVerificationCode('');
        setVerificationError('');
        setTime(180);
        setUserData(null);
        setTooManyAttempts(false);
        dispatch(uactions.error());
    };

    const LoginView = (
        <>
            <h2>{t('landing-signin.welcome') || 'Welcome Back!'}</h2>
            <p className="text-center text-muted mb-4">
                {t('landing-signin.subtitle') || 'Please sign in to your account'}
            </p>

            {error && <Alert variant="danger">{error}</Alert>}

            <div className="mb-4">
                <AuthGoogleComponent locale={i18n.language}/>
            </div>

            <div className="text-center">
                <div className="landing-signin-divider">
                    <span className="text-muted">{t('landing-signin.orDivider') || 'or'}</span>
                </div>
            </div>

            <Form onSubmit={handleEmailSignIn}>
                <Form.Group className="mb-4">
                    <Form.Label>{t('landing-signin.email') || 'Email'}</Form.Label>
                    <Form.Control
                        type="email"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailInvalid(false);
                        }}
                        isInvalid={emailInvalid}
                        placeholder={t('landing-signin.emailPlaceholder') || 'Enter your email'}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Check
                        type="checkbox"
                        label={t('landing-signin.rememberMe') || 'Remember me'}
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                    />
                </Form.Group>

                <Button 
                    variant="primary" 
                    type="submit"
                    style={{ backgroundColor: '#287233', border: 'none' }}
                    className="w-100 mb-3"
                >
                    {t('landing-signin.submit') || 'Continue with Email'}
                </Button>
            </Form>
        </>
    );
 
    const VerificationView = (
        <>
            <h2>{t('verificationPage.title')}</h2>
            <p className="text-center text-muted mb-4">{t('verificationPage.instructions')}</p>

            {verificationError && <Alert variant="danger">{verificationError}</Alert>}

            {!tooManyAttempts ? (
                <>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <Form.Group className="mb-4">
                            <Form.Label>{t('verificationPage.codeLabel')}</Form.Label>
                            <Form.Control
                                type="text"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                                placeholder={t('verificationPage.codePlaceholder')}
                                required
                            />
                        </Form.Group>

                        <div className="row">
                            <div className="col-6">
                                <Button 
                                    variant="secondary" 
                                    onClick={handleCancel}
                                    className="w-100"
                                >
                                    {t('verificationPage.cancelButton')}
                                </Button>
                            </div>
                            <div className="col-6">
                                <Button 
                                    variant="primary"
                                    onClick={handleVerification}
                                    style={{ backgroundColor: '#287233', border: 'none' }}
                                    className="w-100"
                                >
                                    {t('verificationPage.validateButton')}
                                </Button>
                            </div>
                        </div>
                    </Form>
                    <div className="text-center mt-4">
                        <h3>{formatTime(time)}</h3>
                    </div>
                </>
            ) : (
                <div className="text-center">
                    <Button 
                        variant="primary"
                        onClick={() => handleBackToHome()}
                        style={{ backgroundColor: '#287233', border: 'none' }}
                        className="w-100 mt-3"
                    >
                        {t('landing-signin.verification.backToHome') || 'Back to Home'}
                    </Button>
                </div>
            )}
        </>
    );

    return (
        <div className="landing-signin-container">
            <div className="landing-signin-box">
                {!isVerification ? LoginView : VerificationView}
            </div>
        </div>
    );
}

export default LandingSignin; 