// system imports
import * as sservice from './_standard-service'
 
const SERVICE_PATH = 'documents';
  
const get = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/${params.language}/${params.name}/`
    }

    return sservice.get(sparams, params, dispatch)
}
 
const getHelpResources = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/helpresources/${params.language}`
    }

    return sservice.get(sparams, params, dispatch)
} 

export { get, getHelpResources }