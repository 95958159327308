import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Tab, Tabs, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as aService from '../../utilities/services/account-service';
import OutputField from '../layouts/fields/output-field';
import UserContext from '../../user/user-context'
import InputField from '../layouts/fields/input-field';
import * as c from '../configurations/constants'

const SetBalanceContainerModal = ({ close, configs, service, showOnlyInitial = false }) => {

    const element = useSelector((state) => state[configs.object.sliceName].item);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const user = useContext(UserContext)

    const [activeTab, setActiveTab] = useState('initial');
    const [amount, setAmount] = useState(0);
    const [isApplying, setIsApplying] = useState(false);

    const handleSubmit = async (type) => {
        try {
            setIsApplying(true);
            await aService.setBalance(
                {
                    payload: {
                        id: element.id,
                        amount: amount,
                        is_init: type === 'initial',
                    },
                    user: user,
                },
                dispatch
            );
 
            await service.get({ payload: { id: element.id }, user: user }, dispatch);
            close();
        } catch (error) {
            console.error('Error setting balance:', error);
        } finally {
            setIsApplying(false);
        }
    };
 
    if (!element) return null;

    return (
        <Modal show={true} onHide={close} className="custom-modal" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{t('setbalancemodal.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {showOnlyInitial ? (
                    <>
                        <div className="mb-4 p-3 bg-light rounded border">
                            <p>{t('setbalancemodal.initialDescription')}</p>
                            <div>
                                {t('setbalancemodal.current_initial_balance')}:
                                <strong className="ms-1">
                                    <OutputField
                                        value={element.initial_balance_original}
                                        vConfig={{ type: 'currency' }}
                                        user={user}
                                    />
                                </strong>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>{t('setbalancemodal.initial')}</label>
                            <InputField
                                value={amount}
                                vConfig={{
                                    type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                                    isRequired: false,
                                    isEditable: true
                                }}
                                user={user}
                                onChange={(value) => setAmount(parseFloat(value) || 0)}
                                isValid={true}
                                action="edit"
                            />
                        </div>
                    </>
                ) : (
                    <Tabs
                        id="balance-tabs"
                        activeKey={activeTab}
                        onSelect={(k) => setActiveTab(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="initial" title={t('setbalancemodal.initialTab')}>
                            <div className="mb-4 p-3 bg-light rounded border">
                                <p>{t('setbalancemodal.initialDescription')}</p>
                                <div>
                                    {t('setbalancemodal.current_initial_balance')}:
                                    <strong className="ms-1">
                                        <OutputField
                                            value={element.initial_balance_original}
                                            vConfig={{ type: 'currency' }}
                                            user={user}
                                        />
                                    </strong>
                                </div>
                            </div>
                            {activeTab === 'initial' && (
                                <>
                                    <div className="form-group">
                                        <label>{t('setbalancemodal.initial')}</label>
                                        <InputField
                                            value={amount}
                                            vConfig={{
                                                type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                                                isRequired: false,
                                                isEditable: true
                                            }}
                                            user={user}
                                            onChange={(value) => setAmount(parseFloat(value) || 0)}
                                            isValid={true}
                                            action="edit"
                                        />
                                    </div>
                                </>
                            )}
                        </Tab>
                        <Tab eventKey="adjustment" title={t('setbalancemodal.adjustmentTab')}>
                            <div className="mb-4 p-3 bg-light rounded border">
                                <p>{t('setbalancemodal.adjustmentDescription')}</p>
                                <div>
                                    {t('setbalancemodal.current_balance')}:
                                    <strong className="ms-1">
                                        <OutputField
                                            value={element.balance}
                                            vConfig={{ type: 'currency' }}
                                            user={user}
                                        />
                                    </strong>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>{t('setbalancemodal.amount')}</label>
                                <InputField
                                    value={amount}
                                    vConfig={{
                                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                                        isRequired: false,
                                        isEditable: true
                                    }}
                                    user={user}
                                    onChange={(value) => setAmount(parseFloat(value) || 0)}
                                    isValid={true}
                                    action="edit"
                                />
                            </div>
                        </Tab>
                    </Tabs>
                )}
            </Modal.Body>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-custom-secondary btn-sm"
                    onClick={close}
                >
                    {t('setbalancemodal.cancel')}
                </button>
                <button
                    type="button"
                    className="btn btn-custom-primary btn-sm"
                    onClick={() => handleSubmit(showOnlyInitial ? 'initial' : activeTab)}
                    disabled={isApplying}
                >
                    {isApplying ? t('setbalancemodal.applying') : t('setbalancemodal.apply')}
                </button>
            </div>
        </Modal>
    );
};

export default SetBalanceContainerModal;