import treeConfigTemplates from '../../common/configurations/tree-configurations'
import configTemplates from '../../common/configurations' 
import * as c from "../../common/configurations/constants"
import * as tc from "../../common/configurations/text-constants"
import * as cc from "../../common/configurations/color-constants"
 
const configurations = treeConfigTemplates.getTreeConfigurations()
const elementTemplate = configTemplates.getObjectElement()


const configs = {

    ...configurations,

    actions: {

        ...configurations.actions,

        edit: {
            enable: true,
            handler: function() { console.log('edit')}
        },
        remove: {
            enable: true,
            handler: function() { console.log('remove')}
        },
        addNewChild: {
            enable: true,
            handler: function() { console.log('addNewChild')}
        },
        search: {
            enable: true,
            handler: function() { console.log('search')}
        },
        move: {
            enable: true,
            handler: function() { console.log('move')}
        },
        newAccountTransaction: {
            enable: false,
            handler: function() { console.log('newAccountTransaction')}
        },
    },

    layout: {

        ...configurations.layout,

        sections: [
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'name',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_NAME,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'data',
                ncols: 1,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'description',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_DESCRIPTION
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'balance',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_ACCOUNT_BALANCE,
                        isEditable: false,
                        isCreatable: false,
                    },
                    { 
                        ...elementTemplate,
                        name: 'balance_month',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_ACCOUNT_BALANCE_MONTH,
                        isEditable: false,
                        isCreatable: false,
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                isVisibleByField: 'is_leaf',
                elements: [
                    {
                        ...elementTemplate,
                        name: 'currency_id',
                        label: tc.OBJECT_FIELD_PROFILE_CURRENCY,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_USER_CURRENCY,
                        isSelectorLinked: false,
                        isMultiCurrency: true,
                        isRequired: false
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'separator_space'
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'created_date',
                        label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                    {
                        ...elementTemplate,
                        name: 'modified_date',
                        label: tc.OBJECT_FIELD_GENERIC_LASTUPDATE_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                ]
            },
            
        ],

        boostrapIcon: 'bi-currency-exchange',
        color: cc.ASSET_ACCOUNT
    },

    object: {
        name: 'account_asset',
        label: tc.OBJECT_ACCOUNT_ACTIVE_LABEL,
        pluralLabel: tc.OBJECT_ACCOUNT_ACTIVE_PLURAL_LABEL,
        basePath: 'account-assets',
        sliceName: 'accountAsset',
        menuLabel: tc.NAV_MENU_ACCOUNT_ASSET_LABEL,
        transactionPath: 'assets'
    },

    treelist: {
        items: [
            {
                name: 'balance',
                type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                label: tc.OBJECT_FIELD_ACCOUNT_BALANCE,
                isLinked: false,
                isCompactVisible: true
            },
            {
                name: 'balance_month',
                type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                label: tc.OBJECT_FIELD_ACCOUNT_BALANCE_MONTH,
                isLinked: false
            }
        ]
    },

    relatedlist: [
        {
            label: tc.OBJECT_RELATED_LIST_ACCOUNT_LAST_TRANSACTIONS,
            name: 'transaction-account'
        },
        {
            label: tc.OBJECT_RELATED_LIST_ACCOUNT_CALENDAR_LAST_TRANSACTIONS,
            name: 'calendar-transaction-account'
        }
    ]
}
 
export default configs
