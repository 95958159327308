import React from 'react';
import './title.css';

const TitleComponent = ({ title }) => {
  return (
    <div className="title-container d-flex align-items-center">
      <div className="title-border" />
      <span className="title-text text-truncate text-uppercase">{title}</span>
    </div>
  );
};

export default TitleComponent;