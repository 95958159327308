// system imports
import * as gservice from './_generic-service'
import * as sservice from './_standard-service'
 
import * as actions from '../redux/actions/chart-slice';
 
const SERVICE_PATH = 'charts';

const getNoLimitAll = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.getNoLimitAll(sparams, params, dispatch)
    
}

const getAll = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.getAll(sparams, params, dispatch)
    
}

const getMore = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.getMore(sparams, params, dispatch)
    
}

const get = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.get(sparams, params, dispatch)
    
}

const add = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.add(sparams, params, dispatch)
    
}

const update = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.update(sparams, params, dispatch)
    
}
   
const remove = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.remove(sparams, params, dispatch)
    
}
  
const clone = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    const updateparams = {
        ...params,
        payload: {
            ...params.payload,
            visibility: 'private'
        }
    }

    return gservice.clone(sparams, updateparams, dispatch)
}
 
const setup = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actionError: actions.error
    }

    return sservice.patch(sparams, params, dispatch)
}
  
export { getNoLimitAll, getAll, get, getMore, add, clone, update, setup, remove }