import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getBooks } from '../../utilities/services/phrase-service';
import { useTranslation } from 'react-i18next';

import './landing-books.css';

const LandingBooks = () => {
    const [books, setBooks] = useState([]);
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const fetchBooks = async () => {
            const response = await getBooks({ language: i18n.language }, dispatch);
           
            if (response.success) {
                setBooks(response.data);
            }
        };
        fetchBooks();
    }, []);

    return (
        <div className="books-container">
            <h2 className="section-title">{t('landing-book.recommendedTitle')}</h2>
            <div className="books-grid">
                {books.map((book, index) => (
                    <div className="book-card" key={index}>
                        <a href={book.amazonLink} 
                           target="_blank" 
                           rel="noopener noreferrer" 
                           className="book-link">
                            <div className="book-cover-container">
                                <img src={book.cover} alt={book.name} className="book-cover" />
                                <div className="amazon-overlay">
                                    <span className="amazon-text">{t('landing-book.viewOnAmazon')}</span>
                                    <i className="amazon-icon">🔗</i>
                                </div>
                            </div>
                            <h3 className="book-title">{book.name}</h3>
                            <p className="book-description">{book.description}</p>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LandingBooks; 