import configTemplates from '../../common/configurations'
import * as c from "../../common/configurations/constants"
import * as tc from "../../common/configurations/text-constants"
 
const configurations = configTemplates.getConfigurations()
const elementTemplate = configTemplates.getObjectElement()

const configs = {

    ...configurations,

    actions: {

        ...configurations.actions,

        edit: {
            enable: true,
            handler: function() { console.log('edit')}
        },
        clone: {
            enable: true,
            handler: function() { console.log('clone')}
        },
        add: {
            enable: true,
            handler: function() { console.log('add')}
        },
        addNew: {
            enable: true,
            handler: function() { console.log('add')}
        },
        remove: {
            enable: true,
            handler: function() { console.log('remove')}
        },
        search: {
            enable: true,
            handler: function() { console.log('search')}
        },
        addTemplate: {
            enable: true,
            handler: function() { console.log('template')}
        },
        convertToRealAccount: {
            enable: true,
            label: tc.BUTTON_REAL_ACCOUNT_RECEIVABLE_LABEL,
            handler: function() { console.log('new real account')}
        }
    },

    layout: {

        ...configurations.layout,

        sections: [
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'name',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_NAME,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        name: 'date',
                        type: c.OBJECT_FIELD_DATA_TYPE_DATE,
                        label: tc.OBJECT_FIELD_TRANSACTION_DATE,
                        isRequired: true
                    }
                ]
            },
            {
                type: 'separator_space'
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'amount_original',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT_ORIGINAL,
                        isRequired: true
                    },
                    {
                        ...elementTemplate,
                        name: 'currency_id',
                        label: tc.OBJECT_FIELD_PROFILE_CURRENCY,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_USER_CURRENCY,
                        isSelectorLinked: false,
                        isMultiCurrency: true,
                        isRequired: false
                    },
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        
                        ...elementTemplate,
                        name: 'net_income_original',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_TRANSACTION_INCOME_NET_INCOME_ORIGINAL,
                        isRequired: false
                    },
                    {
                        ...elementTemplate,
                        name: 'rate',
                        type: c.OBJECT_FIELD_DATA_TYPE_NUMBER,
                        label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT_RATE,
                        isCreatable: false,
                        isMultiCurrency: true,
                        isEditable: false
                    },
                    
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'amount',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT,
                        isCreatable: false,
                        isMultiCurrency: true,
                        isEditable: false
                    },
                    {
                        ...elementTemplate,
                        name: 'net_income',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_TRANSACTION_INCOME_NET_INCOME,
                        isCreatable: false,
                        isMultiCurrency: true,
                        isEditable: false
                    },
                ]
            },
            {
                type: 'separator_space'
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'income_id',
                        label: tc.OBJECT_FIELD_TRANSACTION_INCOME_INCOME,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_INCOME_LEAF_ACCOUNT,
                        isSelectorLinked: true, //incomeAccountConfig.object.basePath,
                        isRequired: true
                    },
                    {
                        ...elementTemplate,
                        name: 'target_id',
                        label: tc.OBJECT_FIELD_TRANSACTION_INCOME_TARGET,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_INCOME_RECEIVER,
                        isSelectorLinked: true, //assetAccountConfig.object.basePath,
                        isRequired: true
                    },
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'buyer_id',
                        label: tc.OBJECT_BUYER_LABEL,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_BUYER,
                        isSelectorLinked: true, //buyerConfig.object.basePath,
                        isRequired: false
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'separator_space'
            },
            
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'created_date',
                        label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                    {
                        ...elementTemplate,
                        name: 'modified_date',
                        label: tc.OBJECT_FIELD_GENERIC_LASTUPDATE_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                ]
            }
        ],

        boostrapIcon: 'bi-cash-coin transaction',
        color: '#00695C'
    },

    object: {
        name: 'transaction_income',
        label: tc.OBJECT_TRANSACTION_INCOME_NAME_LABEL,
        pluralLabel: tc.OBJECT_TRANSACTION_INCOME_PLURAL_LABEL,
        basePath: 'incomes',
        sliceName: 'income',
        menuLabel: tc.NAV_MENU_TRANSACTION_INCOME_LABEL
    },

    list: {
        items: [
            {
                name: 'name',
                type: 'text',
                label: tc.OBJECT_FIELD_GENERIC_NAME,
                isLinked: true
            },
            {
                name: 'date',
                type: 'date',
                label: tc.OBJECT_FIELD_TRANSACTION_DATE,
                compactViewLeftDetail: true,
                isLinked: false
            },
            {
                name: 'amount',
                type: 'currency',
                label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT,
                compactViewRightDetail: true,
                isLinked: false
            },
            {
                ...elementTemplate,
                name: 'income',
                label: tc.OBJECT_FIELD_TRANSACTION_INCOME_INCOME,
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT
            },
            {
                name: 'created_date',
                type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                isLinked: false
            }
            
        ]
    },

    relatedlist: [
        {
            label: tc.OBJECT_RELATED_LIST_TRANSACTION_RECEIPT_NAME,
            name: 'transaction-receipt',
            parameters: {
                type: "income"
            }
        }
    ]  
}
 
export default configs