import Icon from '@mdi/react';
import Tooltip from '@mui/material/Tooltip';
import { mdiInformationSlabCircleOutline } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { OBJECT_FIELD_DATA_TYPE_SELECTOR, OBJECT_FIELD_DATA_TYPE_PICKLIST } from "../../configurations/constants"
import * as c from "../../configurations/constants"
import * as tc from "../../configurations/text-constants"
import FormOutputField from "./form-output-field"
 
// Add this function at the beginning of the file
const updateFieldConfig = (fieldConfig, element) => {
    if (!element || !fieldConfig) return fieldConfig;

    const updatedConfig = { ...fieldConfig };

    // List of properties that can be dynamically updated
    const dynamicProperties = ['isEditable', 'isVisible', 'isRequired'];

    dynamicProperties.forEach(prop => {
        const byFieldProp = `${prop}ByField`;
        if (fieldConfig[byFieldProp]) {
            updatedConfig[prop] = fieldConfig[prop] && !!element[fieldConfig[byFieldProp]];
        }
    });

    return updatedConfig;
};

const HelpTooltip = ({ text }) => {
    return (
        <Tooltip title={text} placement='top'>
            <Icon path={mdiInformationSlabCircleOutline} height='15px' width='15px' className='mx-1 text-primary'/>
        </Tooltip>
    )
}

const getCSSBasedOnType = (econfigs) => {
  
    switch (econfigs.type) {

        case c.OBJECT_FIELD_DATA_TYPE_NUMBER:
        case c.OBJECT_FIELD_DATA_TYPE_INTEGER:
        case c.OBJECT_FIELD_DATA_TYPE_CURRENCY:
        case c.OBJECT_FIELD_DATA_TYPE_PERCENT:
            return 'text-end'

        case c.OBJECT_FIELD_DATA_TYPE_TEXT:
        case c.OBJECT_FIELD_DATA_TYPE_TEXTAREA:
        case c.OBJECT_FIELD_DATA_TYPE_SELECTOR:
            return 'text-start'
 
        case c.OBJECT_FIELD_DATA_TYPE_AGGREGATE_DATE:
        case c.OBJECT_FIELD_DATA_TYPE_DATE:
        case c.OBJECT_FIELD_DATA_TYPE_DATETIME:
            return 'text-center'
    
        default:
            return 'text-start'
    }
}

const getSizeCSSBasedOnType = (econfigs) => {
  
    switch (econfigs.type) {

        case c.OBJECT_FIELD_DATA_TYPE_PERCENT:
            return 'custom-column-size-xsm pe-3'

        case c.OBJECT_FIELD_DATA_TYPE_NUMBER:
        case c.OBJECT_FIELD_DATA_TYPE_INTEGER:
        case c.OBJECT_FIELD_DATA_TYPE_CURRENCY:
        case c.OBJECT_FIELD_DATA_TYPE_PERCENT:
            return 'custom-column-size-sm pe-3'

        case c.OBJECT_FIELD_DATA_TYPE_TEXT:
        case c.OBJECT_FIELD_DATA_TYPE_TEXTAREA:
        case c.OBJECT_FIELD_DATA_TYPE_SELECTOR:
            return 'custom-column-size-lg'
 
        case c.OBJECT_FIELD_DATA_TYPE_AGGREGATE_DATE:
            return 'custom-column-size-xsm'

        case c.OBJECT_FIELD_DATA_TYPE_DATE:
        case c.OBJECT_FIELD_DATA_TYPE_DATETIME:
            return 'custom-column-size-sm'
    
        default:
            return 'custom-column-size-lg'
    }
}
  
const getFormattedLabel = (label, user) => {
 
    let nlabel = label
    // about currency
    if(nlabel.includes('[BASE]')) {
  
        if(user && user.currency_code) {
            nlabel = nlabel.replace('[BASE]', ` (${user.currency_code})`)
        }
        else 
            nlabel = nlabel.replace('[BASE]', ``)
    }

    return nlabel
    
}

const FormattedLabel = ({configs, user}) => {
     
    const { t } = useTranslation();

    return (
        <label className="fw-light form-label">
            {getFormattedLabel(t(configs.label), user)}
            {
                configs.description && <HelpTooltip text={configs.description} />
            }
        </label>
    )
}

const getElementValue = (element, econfigs) => {
     
    if(!element)
        return ''
    
    if(econfigs.type === OBJECT_FIELD_DATA_TYPE_SELECTOR) {
        const basicName = econfigs.name.replace("_id", "")
        return {id: element[econfigs.name], name: element[basicName], def: element[`${basicName}_def`]}
    }
     
    if(econfigs.type === OBJECT_FIELD_DATA_TYPE_PICKLIST) {
         
        if(!econfigs.values.find(x => x.value===element[econfigs.name]))
            return {}
            
        return {id:  element[econfigs.name], name: econfigs.values.find(x => x.value===element[econfigs.name]).label }
    }
       
    if(!element[econfigs.name])
        return ''
 
    return element[econfigs.name]
}

const getField = ({isValid, element, user, configs, ncolumns, index, changeHandler, Field, action}) => {
   
    // Update the field config
    const updatedConfigs = updateFieldConfig(configs, element);
   
    if(!updatedConfigs || !updatedConfigs.isVisible) {
        return ncolumns === 1 ? <div /> : <div className="col-12 col-md-6" />
    }
 
    if(updatedConfigs.isMultiCurrency && !user.is_multicurrency) {
        return ncolumns === 1 ? <div /> : <div className="col-12 col-md-6" />
    }

    if(action === 'create' && !updatedConfigs.isCreatable) {
        return ncolumns === 1 ? <div /> : <div className="col-12 col-md-6" />
    }
  
    if(ncolumns === 1)
        return (
            <div className="col-12 mb-3" key={index} >
                <FormattedLabel  configs={updatedConfigs} user={user}/>
                <Field isValid={isValid} user={user} value={getElementValue(element, updatedConfigs)} vConfig={updatedConfigs} onChange={(v) => changeHandler(v, updatedConfigs)} action={action}/> 
            </div>
        )

    return (
        <div className="col-12 col-md-6 mb-3">
            <FormattedLabel  configs={updatedConfigs} user={user}/>
            <Field isValid={isValid} user={user} value={getElementValue(element, updatedConfigs)} vConfig={updatedConfigs} onChange={(v) => changeHandler(v, updatedConfigs)} action={action}/> 
        </div>
    )
}

const getFormOutputField = ({element, user, configs, ncolumns, index, changeHandler, fieldRecordMetadata, Field}) => {
    
    if(!configs)
        return <div/>

    if(!configs.isVisible) {
        return ncolumns === 1?<div />: <div className="col-12 col-md-6"/>
    }

    if(configs.isMultiCurrency && !user.is_multicurrency) {
        return ncolumns === 1?<div />: <div className="col-12 col-md-6"/>
    }
 
    if(ncolumns === 1)
        return (
            <div className="col-12 mb-3" key={index}>
                <FormattedLabel configs={configs} user={user}/>
                <FormOutputField user={user} value={getElementValue(element, configs)} vConfig={configs} fieldRecordMetadata={fieldRecordMetadata} onChange={(v) => changeHandler(v, configs)}/> 
            </div>
        )

    return (
        <div className="col-12 col-md-6 mb-3">
            <FormattedLabel configs={configs} user={user}/>
            <FormOutputField user={user} value={getElementValue(element, configs)} vConfig={configs} fieldRecordMetadata={fieldRecordMetadata}  onChange={(v) => changeHandler(v, configs)}/> 
        </div>
    )
}

const getFieldLayout = (user, configs, getColumn, element) => {
    
    const sectionTemplates = configs.layout.sections.filter(s => !(s.hideFn && s.hideFn(user)))
   
    return sectionTemplates.map((s, i) => {
         
        if(s.isVisibleByField && (!element || !element[s.isVisibleByField]))
            return <div key={i}></div>

        if(s.type === 'data') {

            if(s.ncols === 1) {
                return getColumn( s.elements[0], s.ncols, i)
            }

            else if(s.ncols === 2) {
                return (
                    <div className="row" key={i}>
                        {
                            getColumn( s.elements[0])
                        }
                        {
                            getColumn( s.elements[1])
                        }
                    </div>
                )
            }

            else if(s.ncols === 3) {
                return (
                    <div className="row" key={i}>
                        {
                            getColumn( s.elements[0])
                        }
                        {
                            getColumn( s.elements[1])
                        }
                        {
                            getColumn( s.elements[2])
                        }
                    </div>
                )
            }
        }

        else if(s.type === 'separator_line') {
            return (
                <hr key={i}/>
            )
        }

        else if(s.type === 'separator_space') {
            return (
                <div key={i} className='bg-light py-1 rounded mb-2'>
                    <br/>
                </div>
            )
        }

        else if(s.type === 'header') {
            return (
                <div key={i}>
                    { s.text }
                </div>
            )
        }

        return <div key={i}/>

    })
}

const validateInputs = (element, configs) => {

    const st = configs.layout.sections

    const fields = []

    for (let index = 0; index < st.length; index++) {
 
        if(st[index].type !== 'data') continue

        fields.push(...st[index].elements.map(c => { return c}))
    }
      
    const localInvalidFields = []
    // validate required fields
    for (let index = 0; index < fields.length; index++) {
        
        const field = fields[index]
        if(field.name === '-' | !field.isRequired)
            continue;

        if(!element[field.name])
            localInvalidFields.push(field.name)
    }

   return localInvalidFields
}
 
export {
    getField,
    getFormOutputField,
    getFieldLayout,
    validateInputs,
    getCSSBasedOnType,
    getSizeCSSBasedOnType,
    getFormattedLabel
}