import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as documentService from "../../../utilities/services/document-service";
import "./authenticated-welcome.css"; // Estilos personalizados
import Icon from "@mdi/react";
import { mdiChevronRight, mdiClose, mdiCreditCardOutline, mdiBankOutline, mdiHelpCircleOutline, mdiPlayCircleOutline } from "@mdi/js";

const WelcomeModal = ({ isVisible, onClose, navigate }) => {
    const { t, i18n } = useTranslation();
    const [documentUrl, setDocumentUrl] = useState("");
    const [videoUrl, setVideoUrl] = useState("");

    useEffect(() => {
        fetchHelpResources();
    }, [i18n.language]);

    const fetchHelpResources = async () => {
        try {
            const response = await documentService.getHelpResources({
                language: i18n.language,
            });
            if (response?.data) {
                setDocumentUrl(response.data.documentationUrl);
                setVideoUrl(response.data.videoUrl);
            }
        } catch (error) {
            console.error("Error al obtener recursos de ayuda:", error);
        }
    };

    const handleClose = () => {
        onClose();
    };
 
    const handleOpenDocumentation = () => {
        if (documentUrl) {
            window.open(documentUrl, "_blank");
        }
    };

    const handleOpenVideo = () => {
        if (videoUrl) {
            window.open(videoUrl, "_blank");
            handleClose(); // Cerrar el modal después de abrir el video
        }
    };

    return (
        <Modal show={isVisible} onHide={handleClose} centered size="lg" className="authenticated-welcome">
            <div className="modal-header custom-header">
                <h5 className="modal-title">{t("landing.welcome.title")}</h5>
                <button type="button" className="close close-button" onClick={handleClose}>
                    <Icon path={mdiClose} size={1} />
                </button>
            </div>
            <Modal.Body>
                <p className="description">{t("landing.welcome.description")}</p>
 
                <div className="section ">
                    <h4 className="section-title">{t("landing.welcome.help_resources_title")}</h4>
                    <p className="section-description">{t("landing.welcome.help_resources_description")}</p>

                    <div className="help-section">
                        <div
                            className={`card help-option ${!documentUrl ? "disabled" : ""}`}
                            onClick={documentUrl ? handleOpenDocumentation : undefined}
                        >
                            <div className="card-body">
                                <Icon path={mdiHelpCircleOutline} size={1.5} className="icon-green" />
                                <div className="text-container">
                                    <h5 className="option-title">{t("landing.welcome.documentation_title")}</h5>
                                </div>
                                <Icon path={mdiChevronRight} size={1} className="icon-green" />
                            </div>
                        </div>

                        <div
                            className={`card help-option ${!videoUrl ? "disabled" : ""}`}
                            onClick={videoUrl ? handleOpenVideo : undefined}
                        >
                            <div className="card-body">
                                <Icon path={mdiPlayCircleOutline} size={1.5} className="icon-green" />
                                <div className="text-container">
                                    <h5 className="option-title">{t("landing.welcome.video_tutorial_title")}</h5>
                                </div>
                                <Icon path={mdiChevronRight} size={1} className="icon-green" />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default WelcomeModal;
