import React from 'react';
import Icon from '@mdi/react';
import { mdiEmail, mdiPhone } from '@mdi/js';

function ContactUsSection({ t }) {
    return (
        <div className="features-container">
            <div className="features-grid">
                <div className="text-center mb-5">
                    <h2 className="section-title">{t('landing-page.contactUsTitle')}</h2>
                    <p className="text-muted">{t('landing-page.contactUsSubtitle')}</p>
                </div>
                <div className="card shadow-sm">
                    <div className="card-body p-4">
                    
                        <p>{t('landing-page.contactUsText')}</p>
                        <ul className="list-unstyled">
                            <li className="mb-3">
                                <strong>
                                    <Icon path={mdiEmail} size={1} className="me-2 text-primary" />
                                </strong>
                                {t('landing-page.contactUsEmail')}
                            </li>
                            <li className="mb-3">
                                <strong>
                                    <Icon path={mdiPhone} size={1} className="me-2 text-primary" />
                                </strong>
                                {t('landing-page.contactUsPhone')}
                            </li>
                        </ul>
                        <p>{t('landing-page.contactUsClosing')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUsSection; 