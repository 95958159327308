import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiFacebook, mdiYoutube, mdiInstagram } from '@mdi/js';

import LandingHeader from './landing-header';
import LandingMain from './landing-main';
import PricingPlans from './landing-plans';
import ContactUsSection from './landing-contactus';
import * as docservice from '../../utilities/services/document-service';
import * as phraseservice from '../../utilities/services/phrase-service';
import { DOCUMENT_TERMS_CONDITIONS } from "../../common/configurations/constants";
import LandingFeatures from './landing-features';
import LandingSignin from './landing-signin';
import LandingBooks from './landing-books';
import LandingGlossary from './landing-glossary';

import './landing-page.css';

function LandingPage() {
    const navigate = useNavigate();
    const [termCondDoc, setTermCondDoc] = useState('');
    const [phrase, setPhrase] = useState('');
    const [loadingPhrase, setLoadingPhrase] = useState(true);
    const [showPricing, setShowPricing] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [showSignIn, setShowSignIn] = useState(false);
    const [showFeatures, setShowFeatures] = useState(false);

    const { t, i18n } = useTranslation();
     
    useEffect(() => {
        // Obtener el idioma del navegador
        const browserLanguage = navigator.language || navigator.languages[0];
        const shortLang = browserLanguage.split('-')[0]; // Para obtener solo el código principal, ej. 'en'
    
        // Cambiar el idioma en i18n si es diferente al actual
        if (i18n.language !== shortLang) {
            i18n.changeLanguage(shortLang);
        }
    }, [i18n]);
    
    useEffect(() => {
        const load = async () => {
            try { 
                const response2 = await docservice.get({ language: i18n.language, name: DOCUMENT_TERMS_CONDITIONS });
                if (response2.success) setTermCondDoc(response2.data);

                const response3 = await phraseservice.get({ language: i18n.language });
                if (response3.success) setPhrase(response3.data);
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                setLoadingPhrase(false);
            }
        }
        load();
    }, [i18n.language]);
     
    const handlePricingClick = (e) => {
        e.preventDefault();
        setShowPricing(true);
        setShowContact(false);
    };

    const handleContactClick = (e) => {
        e.preventDefault();
        setShowContact(true);
        setShowPricing(false);
    };

    const handleLogoClick = () => {
        setShowPricing(false);
        setShowContact(false);
        setShowSignIn(false);
        setShowFeatures(false);
    };

    const handleSignInClick = () => {
        setShowSignIn(true);
        setShowPricing(false);
        setShowContact(false);
    };

    const handleFeaturesClick = (e) => {
        e.preventDefault();
        setShowFeatures(true);
        setShowPricing(false);
        setShowContact(false);
        setShowSignIn(false);
    };

    const handleBackToHome = () => {
        setShowPricing(false);
        setShowContact(false);
        setShowSignIn(false);
    };

    return (
        <div className="App">
            <div className="watermark"></div>
            
            <LandingHeader 
                t={t} 
                i18n={i18n} 
                handlePricingClick={handlePricingClick}
                handleContactClick={handleContactClick}
                handleLogoClick={handleLogoClick}
                handleSignInClick={handleSignInClick}
                handleFeaturesClick={handleFeaturesClick}
            />

            <Container fluid>
                {showPricing ? (
                    <PricingPlans t={t} i18n={i18n} handleSignInClick={handleSignInClick}/>
                ) : showContact ? (
                    <ContactUsSection t={t} />
                ) : showSignIn ? (
                    <LandingSignin t={t} i18n={i18n} handleBackToHome={handleBackToHome} />
                ) : showFeatures ? (
                    <LandingFeatures t={t} />
                ) : (
                    <>
                        <LandingMain 
                            t={t} 
                            i18n={i18n} 
                            loadingPhrase={loadingPhrase} 
                            phrase={phrase} 
                            onSignInClick={handleSignInClick}
                        />
                        {/* Divisor decorativo */}
                        <div className="decorative-divider">
                            <div className="divider-line"></div>
                            <div className="divider-line"></div>
                        </div>

                        <LandingBooks />

                        {/* Divisor decorativo */}
                        <div className="decorative-divider">
                            <div className="divider-line"></div>
                            <div className="divider-line"></div>
                        </div>

                        <LandingGlossary />
                    </>
                    
                )}
            </Container>
 
            <footer className="footer">
                <div className="footer-content">
                    <span>{t('footerRights')}</span>
                    <span className="separator">|</span>
                    <a href={termCondDoc.url} target="_blank" rel="noopener noreferrer">
                        {t('footerPolicy')}
                    </a>
                    <span className="separator">|</span>
                    <div className="social-media-links">
                        <a href="https://www.facebook.com/profile.php?id=61567121362684" target="_blank" rel="noopener noreferrer">
                            <Icon path={mdiFacebook} size={1} />
                        </a>
                        <a href="https://www.youtube.com/@FinanceGo360" target="_blank" rel="noopener noreferrer">
                            <Icon path={mdiYoutube} size={1} />
                        </a>
                        <a href="https://www.instagram.com/financego360/" target="_blank" rel="noopener noreferrer">
                            <Icon path={mdiInstagram} size={1} />
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default LandingPage;