import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

 
import { Modal } from "react-bootstrap";
import { getFieldLayout, getField, validateInputs } from "../../common/layouts/fields/utility-field";
import InputField from "../../common/layouts/fields/input-field";
import UserContext from '../../user/user-context';

import * as c from "../../common/configurations/constants"
import * as tc from "../../common/configurations/text-constants"
  
const TemplateEntryModal = ({modalConfigs, configs, service}) => {
    
    const user = useContext(UserContext)

    const navigate = useNavigate()

    const { t } = useTranslation();

    const item = useSelector((state)=> state[configs.object.sliceName].item) 
  
    const [elementCopy, setElementCopy] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [invalidFields, setInvalidFields] = useState([])

    const dispatch = useDispatch()
    
    let caction = configs.c_actions.find(a => a.label == tc.BUTTON_ADD_TEMPLATE_ENTRY_LABEL)
    if(caction)
        caction.handler = () => setShowModal(true)
 
    const changeHandler = (value, econfig) => {  
        elementCopy[econfig.name] = value
        setElementCopy({...elementCopy}) 
    }

    const saveHandler = async () => {
        const noValidInputs = validateInputs(elementCopy, modalConfigs)
        if(noValidInputs.length > 0) {
            setInvalidFields(noValidInputs)
            return
        }
 
        const response = await service.addTransaction({payload: elementCopy, item, user: user }, dispatch)
        if(response.success)
            navigate(`/${modalConfigs.object.basePath}/${response.data.id}/view`)
    }

    const getColumn = (e, ncols, index) => {
        return getField({ isValid: invalidFields.indexOf(e.name) ===-1, element: elementCopy, user, configs: e, ncolumns: ncols, index, changeHandler, Field: InputField, action:'create'})
    }

    useEffect(() => {
        setElementCopy({...item})
    }, [item])
 
    return (
        <Modal show={showModal} onHide={()=> setShowModal(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">{`${t("new-page.new")} ${t(configs.object.label)}`}</Modal.Title>
            </Modal.Header>        
            <Modal.Body>
            <form className="needsValidation" noValidate>
                { getFieldLayout( user, modalConfigs, getColumn) }
            </form>     
            </Modal.Body>
        
            <Modal.Footer>
                <button className="btn btn-custom-secondary btn-sm" onClick={()=> setShowModal(false)}>{t(tc.BUTTON_CANCEL_LABEL)}</button>
                <button type="button" className="btn btn-custom-primary btn-sm" variant="primary" onClick={saveHandler} >{t(tc.BUTTON_SAVE_LABEL)}</button>
            </Modal.Footer>
        </Modal>
    )
}
export default TemplateEntryModal