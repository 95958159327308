import configTemplates from '../../../common/configurations'
import * as c from "../../../common/configurations/constants"
import * as tc from "../../../common/configurations/text-constants"

const configurations = configTemplates.getConfigurations()
const elementTemplate = configTemplates.getObjectElement()

const configs = {

    ...configurations,

    actions: {

        ...configurations.actions,

        edit: {
            enable: true,
            handler: function() { console.log('edit')}
        },
        clone: {
            enable: true,
            handler: function() { console.log('clone')}
        },
        add: {
            enable: true,
            handler: function() { console.log('add')}
        },
        addNew: {
            enable: true,
            handler: function() { console.log('add')}
        },
        remove: {
            enable: true,
            handler: function() { console.log('remove')},
            visibilityFn: (e) => !e.is_standard_leaf
        },
        search: {
            enable: true,
            handler: function() { console.log('search')}
        },
        listviews: {
            enable: false
        }
    },

    layout: {

        ...configurations.layout,

        sections: [
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'name',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_NAME,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'data',
                ncols: 1,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'description',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_DESCRIPTION
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    { 
                        ...elementTemplate,
                        name: 'balance',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_ACCOUNT_BALANCE,
                        isEditable: false,
                        isCreatable: false,
                    },
                    { 
                        ...elementTemplate,
                        name: 'balance_month',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_ACCOUNT_BALANCE_MONTH,
                        isEditable: false,
                        isCreatable: false,
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'currency_id',
                        label: tc.OBJECT_FIELD_PROFILE_CURRENCY,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_USER_CURRENCY,
                        isSelectorLinked: false,
                        isMultiCurrency: true,
                        isRequired: false
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'separator_space'
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'created_date',
                        label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                    {
                        ...elementTemplate,
                        name: 'modified_date',
                        label: tc.OBJECT_FIELD_GENERIC_LASTUPDATE_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                ]
            },
            
        ],

        boostrapIcon: 'bi-wallet'
    },

    object: {
        name: 'cash',
        label: tc.OBJECT_PAYMENT_METHOD_CASH_LABEL,
        pluralLabel: tc.OBJECT_PAYMENT_METHOD_CASH_PLURAL_LABEL,
        basePath: 'pmethod/Cashes',
        sliceName: 'paymentMethod',
        menuLabel: tc.NAV_MENU_PAYMENT_METHOD_CASH_LABEL
    },

    list: {
        items: [
            {
                name: 'name',
                type: 'text',
                label: tc.OBJECT_FIELD_GENERIC_NAME,
                isLinked: true
            },
            {
                name: 'balance',
                type: 'currency',
                label: tc.OBJECT_FIELD_ACCOUNT_BALANCE,
                isLinked: false,
                compactViewRightDetail: true,
            },
            {
                name: 'balance_month',
                type: 'currency',
                label: tc.OBJECT_FIELD_ACCOUNT_BALANCE_MONTH,
                isLinked: false
            },
            {
                name: 'modified_date',
                type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                label: tc.OBJECT_FIELD_GENERIC_LASTUPDATE_DATE,
                isLinked: false
            }
            
        ]
    },

    relatedlist: [
        {
            label: tc.OBJECT_RELATED_LIST_ACCOUNT_LAST_TRANSACTIONS,
            name: 'transaction-account'
        },
        {
            label: tc.OBJECT_RELATED_LIST_ACCOUNT_CALENDAR_LAST_TRANSACTIONS,
            name: 'calendar-transaction-account'
        }
    ]
}
 
export default configs