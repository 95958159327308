import React, { useState } from "react";
import { Dropdown, Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import _ from "lodash"
import { useTranslation } from 'react-i18next';

import SimpleHeader from '../../../layouts/headers/simple/simple-header'
import * as tc from '../../../configurations/text-constants'
import * as c from "../../../configurations/constants"
import InputField from "../../../layouts/fields/input-field";

import configTemplates from '../../../configurations'

const accountMoveConfig = (type) => {

    const elementTemplate = configTemplates.getObjectElement()

    let selector = ''
    switch (type) {

        case 'account_expense':
            selector = c.SELECTOR_CONTEXT_MOVE_TARGET_EXPENSE
            break;
        case 'account_income':
            selector = c.SELECTOR_CONTEXT_MOVE_TARGET_INCOME
            break;
        case 'account_liability':
            selector = c.SELECTOR_CONTEXT_MOVE_TARGET_LIABILITY
            break;
        case 'account_asset':
            selector = c.SELECTOR_CONTEXT_MOVE_TARGET_ASSET
            break;
        default:
            break;
    }


    return {
        ...elementTemplate,
        name: 'account_id',
        label: tc.OBJECT_FIELD_ACCOUNT_PARENT,
        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
        context: selector,
        isRequired: true,
        isEditable: false,
    }
}

const TreeItemDetailPageHeader = ({element, configs, user}) => {
  
    const { t } = useTranslation();

    const navigate = useNavigate()

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showMoveModal, setShowMoveModal] = useState(false)
    const [moveTarget, setMoveTarget] = useState()
    const [showDropdown, setShowDropdown] = useState(false);

     /** standard actions  */
    const editHandler = () => {
        navigate(`/${configs.object.basePath}/${element.id}/edit`)
    }
     
    const addAccountTransactionHandler = () => {
        navigate(`/${configs.object.transactionPath}/new/${element.id}`)
    }
     
    const addNewChildHandler = () => {
        navigate(`/${configs.object.basePath}/${element.id}/newc`)
    }

    const moveHandler = async () => {
        configs.actions.move.handler(moveTarget)
    }

    const deleteHandler = () => {
        // show confirmation modal
        setShowDeleteModal(true)
    }

    const actionVisibility = {
        move: element.parent_id && !element.is_def && !element.is_standard && configs.actions.move.enable,
        newtrans: element.is_leaf && !element.is_def && configs.actions.newAccountTransaction.enable,
        newchild: !element.is_standard_leaf && !element.has_transactions && !element.is_def && configs.actions.addNewChild.enable,
        edit: !element.is_standard && configs.actions.edit.enable,
        remove: !element.is_standard && !element.has_transactions && configs.actions.remove.enable
    }

    const deleteConfirmationHandler = () => {
        // show confirmation modal
        configs.actions.remove.handler(element.id)
        setShowDeleteModal(false)
    }
  
    return (
        <SimpleHeader configs={configs} text={element.name} >
 
            <div className="d-none d-md-flex hstack gap-2">
                {/* Original buttons for medium and larger screens */}
                <div className="btn-group" role="group">
                {
                    configs.c_actions && configs.c_actions.filter(a => !a.visibilityFn || a.visibilityFn(element, user)).map((ca, i) => (
                        <button type="button" key={i} className="btn btn-custom-outline btn-sm" style={{minWidth: "130px"}} onClick={(e) => ca.handler(e, element)}>
                        { t(ca.label) }
                        </button>
                    ))
                }
                </div>

                <div className="btn-group" role="group">
                {
                    actionVisibility.move &&
                    <button type="button" className="btn btn-custom-primary btn-sm" onClick={() => setShowMoveModal(true)}>
                        {t(tc.BUTTON_MOVE_ACCOUNT_LABEL)}
                    </button>
                }

                { 
                    actionVisibility.newtrans &&
                    <button type="button" className="btn btn-custom-primary btn-sm" onClick={addAccountTransactionHandler}>
                        {t(tc.BUTTON_NEW_TRANSACTION_FROM_ACCOUNT_LABEL)}
                    </button>
                }

                {
                    actionVisibility.newchild &&
                    <button type="button" className="btn btn-custom-primary btn-sm" onClick={addNewChildHandler}>
                        {t(tc.BUTTON_NEW_CHILD_LABEL)}
                    </button>
                }

                {
                    actionVisibility.edit &&
                    <button type="button" className="btn btn-custom-primary btn-sm" onClick={editHandler}>
                        {t(tc.BUTTON_EDIT_LABEL)}
                    </button>
                }
                </div>

                <div className="btn-group" role="group">
                {
                    actionVisibility.remove &&
                    <button type="button" className="btn btn-custom-delete btn-sm" onClick={deleteHandler}>
                        {t(tc.BUTTON_REMOVE_LABEL)}
                    </button>
                }
                </div>
            </div>

            {/* Dropdown button for small screens */}
            <div className="d-block d-md-none">
            <Dropdown show={showDropdown} onToggle={() => setShowDropdown(!showDropdown)}>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        {t(tc.SMALL_DEVICE_OPTION_LABEL)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {
                            configs.c_actions && configs.c_actions.filter(a => !a.visibilityFn || a.visibilityFn(element, user)).map((ca, i) => (
                                <Dropdown.Item key={i} className="text-secondary" onClick={(e) => ca.handler(e, element)}>
                                    { t(ca.label) }
                                </Dropdown.Item>
                            ))
                        }

                        {
                            configs.c_actions && configs.c_actions.filter(a => !a.visibilityFn || a.visibilityFn(element, user)).length > 0 &&
                            <Dropdown.Divider />
                        }
                         
                        {
                            /** if you change this condition you have to change the above condition too */
                            actionVisibility.move &&
                            <Dropdown.Item className="text-primary" onClick={() => setShowMoveModal(true)}>
                                {t(tc.BUTTON_MOVE_ACCOUNT_LABEL)}
                            </Dropdown.Item>
                        }

                        {
                            actionVisibility.newtrans &&
                            <Dropdown.Item className="text-primary" onClick={addAccountTransactionHandler}>
                                {t(tc.BUTTON_NEW_TRANSACTION_FROM_ACCOUNT_LABEL)}
                            </Dropdown.Item>
                        }

                        {
                            actionVisibility.newchild &&
                            <Dropdown.Item className="text-primary" onClick={addNewChildHandler}>
                                {t(tc.BUTTON_NEW_CHILD_LABEL)}
                            </Dropdown.Item>
                        }

                        { 
                            actionVisibility.edit &&
                            <Dropdown.Item className="text-primary" onClick={editHandler}>
                                {t(tc.BUTTON_EDIT_LABEL)}
                            </Dropdown.Item>
                        }

                        {
                            actionVisibility.remove &&
                            <Dropdown.Divider />
                        }

                        {
                            /** if you change this condition you have to change the above condition too */
                            actionVisibility.remove &&
                            <Dropdown.Item className="text-danger" onClick={deleteHandler}>
                                {t(tc.BUTTON_REMOVE_LABEL)}
                            </Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            {/* Modals */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                <Modal.Title as="div">{t(tc.MODAL_DELETE_HEADER)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>{t(tc.MODAL_DELETE_BODY_MESSAGE).replace('[RECORD_NAME]', element.name)}</p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="outline-primary" size="sm" onClick={() => setShowDeleteModal(false)}>
                    {t(tc.BUTTON_CANCEL_LABEL)}
                </Button>
                <Button variant="outline-danger" size="sm" onClick={deleteConfirmationHandler}>
                    {t(tc.BUTTON_REMOVE_LABEL)}
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showMoveModal} onHide={() => setShowMoveModal(false)}>
                <Modal.Header closeButton>
                <Modal.Title as="div">{t(tc.MODAL_MOVE_ACCOUNT_HEADER)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t(tc.MODAL_MOVE_ACCOUNT_MESSAGE)}</p>
                    <InputField value={moveTarget} vConfig={accountMoveConfig(configs.object.name)} user={user} onChange={(v) => setMoveTarget(v)} isValid={true} action={'create'} />
                </Modal.Body>
                <Modal.Footer>
                <Button variant="outline-secondary" size="sm" onClick={() => setShowMoveModal(false)}>
                    {t(tc.BUTTON_CANCEL_LABEL)}
                </Button>
                <Button variant="outline-primary" size="sm" onClick={moveHandler} disabled={!moveTarget}>
                    {t(tc.BUTTON_SAVE_LABEL)}
                </Button>
                </Modal.Footer>
            </Modal>

        </SimpleHeader>
       
    )
}

export default TreeItemDetailPageHeader