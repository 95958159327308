import React from 'react';
import Icon from '@mdi/react';
import { mdiCheckCircle } from '@mdi/js';
import './landing-plans.css';

const PricingPlans = ({ t, handleSignInClick }) => {
  const plans = [
    {
      name: t('landing-plan.free.name'),
      price: '0',
      period: '',
      features: [
        t('landing-plan.free.feature-1'),
        t('landing-plan.free.feature-2'),
        t('landing-plan.free.feature-3'),
        t('landing-plan.free.feature-4'),
        t('landing-plan.free.feature-5'),
        t('landing-plan.free.feature-6')
      ],
      footerText: t('landing-plan.free.footerText'),
      highlighted: false,
      button: true
    },
    {
      name: t('landing-plan.premium-monthly.name'),
      monthlyPrice: '2.99',
      annualMonthlyPrice: '2.49',
      features: [
        t('landing-plan.premium-monthly.feature-1'),
        t('landing-plan.premium-monthly.feature-2'),
        t('landing-plan.premium-monthly.feature-3'),
        t('landing-plan.premium-monthly.feature-4'),
        t('landing-plan.premium-monthly.feature-5'),
        t('landing-plan.premium-monthly.feature-6')
      ],
      footerText: t('landing-plan.premium-monthly.footerText'),
      highlighted: false
    }
  ];

  return (
    <section className="pricing-section py-5">
      <div className="container">
        <div className="text-center mb-5">
          <h2 className="section-title">{t('landing-plan.title')}</h2>
          <p className="text-muted">{t('landing-plan.description')}</p>
        </div>
        
        {/* Rest of the component remains the same, using t() function for all text */}
        
        <div className="row justify-content-center">
          {plans.map((plan, index) => (
            <div key={index} className="col-lg-6 col-md-6 mb-4">
              <div className={`card pricing-card h-100 ${plan.highlighted ? 'pricing-card-highlighted' : ''}`}>
                <div className="card-header text-center bg-white border-0 pt-4">
                  <h3 className="card-title mb-0">{plan.name}</h3>
                </div>
                <div className="card-body text-center">
                  {plan.price ? (
                    // Free plan pricing
                    <div className="pricing-value mb-4">
                      <span className="currency">$</span>
                      <span className="price">{plan.price}</span>
                      <span className="period">{plan.period}</span>
                    </div>
                  ) : (
                    // Premium plan pricing with monthly/annual options
                    <div className="pricing-value mb-4">
                      <div className="mb-3">
                        <span className="currency">$</span>
                        <span className="price">{plan.annualMonthlyPrice}</span>
                        <span className="period">/{t('landing-plan.period.month')}</span>
                        <div className="text-muted small">{t('landing-plan.annual-billing')}</div>
                      </div>
                      <div className="text-muted small mt-2">
                        {t('landing-plan.monthly-price', { price: plan.monthlyPrice })}
                      </div>
                    </div>
                  )}
                  
                  <ul className="list-unstyled feature-list p-4">
                    {plan.features.map((feature, featureIndex) => (
                      <li
                        key={featureIndex}
                        className="mb-3 d-flex align-items-start text-start"
                      >
                        <Icon
                          path={mdiCheckCircle}
                          size={0.8}
                          className="feature-icon me-2 mt-1"
                        />
                        <span className="feature-text">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="card-footer text-center bg-white border-0 pt-3">
                  {plan.button ? (
                    <button 
                      className="btn btn-custom-primary landing-plans landing-plans_btn-full-width"   
                      onClick={handleSignInClick}
                    >
                      {plan.footerText}
                    </button>
                  ) : (
                    <p className="text-muted mb-0">{plan.footerText}</p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="text-center mt-5">
          <p className="lead">{t('landing-plan.callToAction')}</p>
          <p className="text-muted">{t('landing-plan.upgradeMessage')}</p>
        </div>
      </div>
    </section>
  );
};

export default PricingPlans;