import React from 'react';
import { useTranslation } from 'react-i18next';
import './nodata.css';
import TitleComponent from "../title";

const NoDataAvailable = ({title}) => {

  const { t } = useTranslation();
  
  return (
    <div>
      <TitleComponent title={title} />
      <div className="no-data-container d-flex flex-column align-items-center justify-content-center p-4">
        <h5 className="no-data-text mb-2">{t('data.common.nodata')}</h5>
      </div>
    </div>
  );
};

export default NoDataAvailable;