import React, {useEffect, useState} from "react";
import AuthenticatedContainerBody from './authenticated-container-body'
import AuthenticatedContainerHeader from './authenticated-container-header'
import AuthenticatedContainerSideHeader from './authenticated-container-side-header'
import AuthenticatedContainerCompactSideHeader from "./authenticated-container-compact-side-header";
import configs from '../../../utilities/configuration-container'
import './authenticated-container.css'
import AdsComponent from "../../../common/ads/ads-component";
import WelcomeModal from "./authenticated-welcome";
import { useNavigate } from 'react-router-dom';

const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
};

const setCookie = (name, value, days) => {
    const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
    document.cookie = `${name}=${value}; expires=${expires}; path=/; Secure; SameSite=Strict`;
};
 
const AuthenticatedContainer = ({user}) => {

    const [isModalVisible, setIsModalVisible] = useState(() => {
        const hasShownModal = getCookie('welcomeModalShown');
        return !hasShownModal;
    });
    
    const navigate = useNavigate();

    const sideMenus = user.is_admin? [
        configs.transactionEmail,
        configs.dashboardStandard,
        configs.reportStandard,
        configs.chartStandard,
        configs.reportType,
    ]: [
        configs.accountExpense,
        configs.expense,
        configs.expenseTemplate,
        configs.dashboard,
        configs.report,
    ]

    const handleCloseModal = () => {
        setCookie('welcomeModalShown', 'true', 365); // 1 año
        setIsModalVisible(false);
    };
 
    return (
        <section className="container-fuild authenticated-container">
            <AuthenticatedContainerHeader user={user} sideMenus={sideMenus}/>
            <div className="row-container row">
                <div className="body-container col">
                    <div className="row">
                        <div className="col-md-12">
                            <AuthenticatedContainerBody user={user} />
                        </div>
                    </div>
                </div>
            </div>
            <WelcomeModal isVisible={isModalVisible} onClose={handleCloseModal} navigate={navigate}/>
            <div className="footer-supporter d-block d-md-none">
            </div>
        </section>
    )
}

export default AuthenticatedContainer