import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from "react-bootstrap";

import DetailPageV2 from "../../../common/layouts/pages/detail-page/detail-page-v2";
import configs from './configs'
import UserContext from '../../../user/user-context';
import SetBalanceContainerModal from "../../../common/modals/set-balance-container-modal";
import * as s from '../../../utilities/services/payment-method-service'
import UploadStatementModal from "./upload-statement-modal";

const PaymentMethodCardDetailPage = () => {

    const user = useContext(UserContext)
    const { id } = useParams()

    const [showModal,setShowModal] = useState(false)
    const [showSetBalanceModal, setShowSetBalanceModal] = useState(false)

    configs.c_actions = [
        {
            label: 'payment-method.statement',
            handler: () => setShowModal(true)
        },
        {
            label: 'BUTTON_SET_BALANCE_LABEL',
            handler: () => setShowSetBalanceModal(true)
        }
    ]

    return (
        <DetailPageV2 service={s} configs={configs}>
            {
                showModal && 
                <UploadStatementModal item={{id}} close={()=> setShowModal(false)} user={user}/>
            }
            {
                showSetBalanceModal &&
                <SetBalanceContainerModal close={()=>setShowSetBalanceModal(false)} configs={configs} showOnlyInitial={true} service={s}/>
            }
        </DetailPageV2>
    )
}

export default PaymentMethodCardDetailPage