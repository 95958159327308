import * as c from './constants'

const PAYMENT_METHODS = [
    {value: c.ACCOUNT_DEFINITION_BANK, label: 'Banco'},
    {value: c.ACCOUNT_DEFINITION_CARD, label: 'Tarjeta de Credito'},
    {value: c.ACCOUNT_DEFINITION_WALLET, label: 'Billetera Digital'}
]

const DATA_VISIBILITY = [
    {value: 'private', label: 'Private'},
    {value: 'public', label: 'Public'},
]

const DASHBOARD_NUMBER_OF_COLUMNS = [
    {value: 1, label: '1'},
    {value: 2, label: '2'}
]

const CHART_LENGEND_LOCATION = [
    {value: 'top', label: 'picklistConstants.top'},
    {value: 'bottom', label: 'picklistConstants.bottom'},
    {value: 'right', label: 'picklistConstants.right'},
    {value: 'left', label: 'picklistConstants.left'},
]

const DATE_FORMATS = [
    {value: 'yyyy/mm/dd', label: 'yyyy/mm/dd'},
    {value: 'dd/mm/yyyy', label: 'dd/mm/yyyy'},
    {value: 't', label: 'today'}
]

const FILTER_OPERATIONS = [
    {
        name: c.FILTER_OPERATION_EQUAL,
        id: 'e',
        allowType: ['text', 'number', 'boolean', 'currency', 'date', 'datetime', 'percent', 'selector', 'aggregated_date']
    },
    {
        name: c.FILTER_OPERATION_NOT_EQUAL,
        id: 'ne',
        allowType: ['text', 'number', 'boolean', 'currency', 'date', 'datetime', 'percent', 'selector', 'aggregated_date']
    },
    {
        name: c.FILTER_OPERATION_GREATER_THAN,
        id: 'gt',
        allowType: ['number', 'currency', 'date', 'datetime', 'percent', 'aggregated_date']
    },
    {
        name: c.FILTER_OPERATION_GREATER_THAN_OR_EQUAL,
        id: 'gte',
        allowType: ['number', 'currency', 'date', 'datetime', 'percent', 'aggregated_date']
    },
    {
        name: c.FILTER_OPERATION_LESS_THAN,
        id: 'lt',
        allowType: ['number', 'currency', 'date', 'datetime', 'percent', 'aggregated_date']
    },
    {
        name: c.FILTER_OPERATION_LESS_THAN_OR_EQUAL,
        id: 'lte',
        allowType: ['number', 'currency', 'date', 'datetime', 'percent', 'aggregated_date']
    },
    {
        name: c.FILTER_OPERATION_CONTAINS,
        id: 'c',
        allowType: ['text', 'textarea', 'textarea_html', 'selector']
    },
    {
        name: c.FILTER_OPERATION_NOT_CONTAINS,
        id: 'nc',
        allowType: ['text', 'textarea', 'textarea_html', 'selector']
    }
]


export {
    PAYMENT_METHODS,
    DATE_FORMATS,
    FILTER_OPERATIONS,
    DASHBOARD_NUMBER_OF_COLUMNS,
    CHART_LENGEND_LOCATION,
    DATA_VISIBILITY
}